const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const formatDate = (
    gameDate: string | Date,
    hideDay = false
): string => {
    const date = new Date(gameDate);
    return `${hideDay ? '' : `${days[date.getUTCDay()]} `}${
        date.getUTCMonth() + 1
    }/${date.getUTCDate()}/${date.getUTCFullYear()}`;
};
