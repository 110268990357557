import gql from 'graphql-tag';

export const contractQuery = gql`
    query contract(
        $id: ID
        $guid: ID
        $blank: Boolean
        $away: Boolean
        $requestEmail: String
        $user: userInput
        $different_signer: Boolean
        $require_away_head_coach_info: String
    ) {
        contract(
            id: $id
            guid: $guid
            blank: $blank
            away: $away
            requestEmail: $requestEmail
            user: $user
            different_signer: $different_signer
            require_away_head_coach_info: $require_away_head_coach_info
        ) {
            html
            message
            status
            contest {
                away_orgid
                away_different_signer
                away_different_signer_email
                away_internal_approvers
                away_requires_internal_approval
                away_read_only_recipients
                contract_recipient
                contract_recipient_name
                home_internal_approvers
                require_away_head_coach_info
                sport
                submitter_email
                submitter_fullname
            }
        }
    }
`;

export const requestContractMutation = gql`
    mutation requestContract(
        $guid: ID
        $sender_email: String
        $sender_name: String
        $message: String
        $recipient: String
    ) {
        requestContract(
            guid: $guid
            sender_email: $sender_email
            sender_name: $sender_name
            message: $message
            recipient: $recipient
        )
    }
`;

export const contactOwnerMutation = gql`
    mutation contactOwner(
        $guid: ID
        $sender_email: String
        $message: String
        $sender_name: String
    ) {
        contactOwner(
            guid: $guid
            sender_email: $sender_email
            sender_name: $sender_name
            message: $message
        )
    }
`;

export const requestCopyMutation = gql`
    mutation requestCopy(
        $guid: ID
        $recipient: String
        $blank: Boolean
        $away: Boolean
        $home: Boolean
        $user_fullname: String
        $user_office_phone: String
        $user_title: String
    ) {
        requestCopy(
            guid: $guid
            recipient: $recipient
            blank: $blank
            away: $away
            home: $home
            user_fullname: $user_fullname
            user_office_phone: $user_office_phone
            user_title: $user_title
        )
    }
`;
