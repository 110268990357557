import React, { useState } from 'react';
import { Popup, Icon } from 'semantic-ui-react';

import {
    statusDescriptions,
    statuses,
    statusIcons,
} from '../../utils/statuses';

const ContestHistoryModal = (props) => {
    const [open, setOpen] = useState(false);
    let {
        contest,
        openStatusModal = () => {},
        openRequestContractModal = () => {},
    } = props;

    return (
        <Popup
            key={contest.id}
            position="top center"
            on="click"
            open={open}
            onClose={() => setOpen(false)}
            trigger={
                <Icon
                    style={{ cursor: 'pointer' }}
                    size="big"
                    onClick={() => setOpen(true)}
                    {...statusIcons[contest.status]}
                />
            }
            content={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <span>{statusDescriptions[contest.status]}</span>
                    </div>
                    <div
                        style={{
                            marginTop: '10px',
                            fontSize: '10px',
                            cursor: 'pointer',
                            border: '1px solid #e0e0e0',
                            padding: '5px',
                            borderRadius: '5px',
                            backgroundColor: '#f0f0f0',
                        }}
                        onClick={() => {
                            openStatusModal();
                            setOpen(false);
                        }}
                    >
                        <span>Click to see history</span>
                    </div>

                    {contest.status === statuses.AWAY_PLACEHOLDER && (
                        <div
                            style={{
                                marginTop: '10px',
                                fontSize: '10px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                padding: '5px',
                                borderRadius: '5px',
                                backgroundColor: '#f0f0f0',
                            }}
                            onClick={() => {
                                openRequestContractModal();
                                setOpen(false);
                            }}
                        >
                            <span>Click to request a contract</span>
                        </div>
                    )}
                </div>
            }
        />
    );
};

export default ContestHistoryModal;
