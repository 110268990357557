import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { useMutation, useQuery } from 'react-apollo-hooks';
import {
    FormRowTextArea,
    FormRowInput,
    FormRowDropdown,
} from '../utilityComponents/form/formRow';
import { sports } from '../../utils/sports';
import { formatDate } from '../../utils/dateUtils';
import { userOrgContactListQuery } from '../../gql/user';
import { requestContractMutation } from '../../gql/contract';
import { toast } from 'react-toastify';

export const RequestContractModal = (props: {
    open: boolean;
    onClose: () => void;
    contest: any;
    user: any;
}): React.ReactElement => {
    const { open, onClose, contest, user } = props;
    const [formState, setFormState] = useState({
        guid: contest.guid,
        sender_name: `${user.firstname} ${user.lastname}`,
        sender_email: user.email,
        recipient: '',
        message: `We haven't received a contract for the ${
            sports[contest.sport]
        } event on ${formatDate(
            contest.date
        )}. Please send as soon as you are able.`,
    });

    const userOrgContactListGql = useQuery(userOrgContactListQuery, {
        variables: {
            orgid: contest.orgid,
        },
        skip: !contest.orgid,
    });

    const requestContractGql = useMutation(requestContractMutation);

    const userOrgContactList =
        userOrgContactListGql.data?.homeUserOrgContactList || [];

    const primaryContact = userOrgContactListGql.data?.homePrimaryContact || {};

    useEffect(() => {
        setFormState({
            guid: contest.guid,
            sender_name: `${user.firstname} ${user.lastname}`,
            sender_email: user.email,
            recipient: primaryContact.email,
            message: `We haven't received a contract for the ${
                sports[contest.sport]
            } event on ${formatDate(
                contest.date
            )}. Please send as soon as you are able.`,
        });
    }, [JSON.stringify(contest), JSON.stringify(userOrgContactListGql)]);

    const formUpdate = (updates: { [key: string]: string }) => {
        setFormState({ ...formState, ...updates });
    };

    const handleSubmit = async () => {
        await requestContractGql({
            variables: formState,
        });
        onClose();
        toast.success(
            'Contract requested successfully. The recipient may respond via email.'
        );
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>Request Contract</Modal.Header>
            <Modal.Content>
                <Form>
                    <FormRowInput
                        field="sender_name"
                        placeholder="Type your name"
                        value={formState.sender_name}
                        label="Your Name"
                        formUpdate={formUpdate}
                    />
                    <FormRowInput
                        field="sender_email"
                        placeholder="Type your email"
                        value={formState.sender_email}
                        label="Your Email"
                        formUpdate={formUpdate}
                    />
                    <FormRowDropdown
                        field="recipient"
                        placeholder="Select a recipient"
                        value={formState.recipient}
                        label="Recipient"
                        options={userOrgContactList.map((contact: any) => ({
                            key: contact.email,
                            text:
                                contact.firstname && contact.lastname
                                    ? `${contact.firstname} ${contact.lastname} <${contact.email}>`
                                    : contact.email,
                            value: contact.email,
                        }))}
                        formUpdate={formUpdate}
                    />
                    <FormRowTextArea
                        field="message"
                        placeholder="Type your message"
                        value={formState.message}
                        label="Message"
                        formUpdate={formUpdate}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Submit</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default RequestContractModal;
